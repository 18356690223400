<template lang="">
<div>
    <h1>Hello world</h1>
</div>
</template>

<script>
export default {};
</script>

<style lang="">
</style>
